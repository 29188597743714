import app from './App.module.css';
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense } from 'react'
import AppConfig from './config/appConfig';
import Loading from './components/loading/Loading';
import Content from './Content/Content';
import useApp from './useApp';
import { Helmet } from 'react-helmet';
import { icon, title } from './constants';

export const SignInDATA = React.createContext()
export const AppContext = React.createContext(new AppConfig())
export const RTLContext = React.createContext()

function App() {
  const controller = useApp()
  const {
    state
  } = controller

  return (
    <Router>
      <AppContext.Provider value={{
        value: state.appConfig,
      }}>
        <RTLContext.Provider value={state.rtl}>
          <div className={app.App} style={{ direction: state.rtl ? "rtl" : "ltr" }}>
            <Helmet>
              <title>{title}</title>
              <link rel="icon" href={icon} />
            </Helmet>
            <Suspense fallback={<Loading />}>
              <Content />
            </Suspense>
          </div>
        </RTLContext.Provider>
      </AppContext.Provider>
    </Router >
  );
}
export default App;