import { Route, Routes } from 'react-router-dom';
import { lazy } from 'react'

const AdminWrapper = lazy(() => import('./Admin'))
const Landing = lazy(() => import('../Landing/Landing'))

function Content() {
    return <Routes>
        <Route path='admin/*' element={<AdminWrapper />} />
        <Route path='' element={<Landing />} />
    </Routes >
}
export default Content