import { useReducer } from 'react'
import AppConfig from './config/appConfig';

const SHOW_ROOT_TERMS = 5
const SHOW_ROOT_TERMS_FROM_TAGS = 6
const REFRESH = 7
const MODIFY_NOTIFICATION = 8

export default function useApp() {

    const reducer = (state, action) => {
        switch (action.type) {
            case SHOW_ROOT_TERMS: {//value
                return {
                    ...state,
                    showRootTerms: action.value
                }
            }
            case SHOW_ROOT_TERMS_FROM_TAGS://value
                return {
                    ...state,
                    showRootTermsFromTags: action.value
                }
            case REFRESH:
                return {
                    ...state
                }
            case MODIFY_NOTIFICATION://param value
                return {
                    ...state,
                    notification: {
                        ...state.notification,
                        [action.param]: action.value
                    }
                }
            default:
                return state
        }
    }

    const initialState = {
        signInVisibile: true,
        loading: false,
        message: '',
        admin: {},
        showRootTerms: false,
        showRootTermsFromTags: false,
        notification: {
            title: '',
            body: ''
        },
        appConfig: new AppConfig(),
        rtl: true,
        activeSideBarItem: '',
    }

    const [state, dispatch] = useReducer(reducer, initialState)
    const { signInVisibile } = state

    function isLoggedIn() {
        return localStorage.getItem("admin") != null
    }

    return {
        state,
        signInVisibile,
        isLoggedIn,
        dispatch
    }
}
